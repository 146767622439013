.loading-icon {
  display: inline-block;
  background-image: url('../../assets/images/accumbo-spinner.png');
  animation: spin 1.5s linear infinite;
  width: 44px;
  height: 44px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}