@import "../../styles/breakpoints.scss";

.card {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  width: 95%;
  padding: 22px 25px 25px 25px;

  @include for-laptop-up {
    max-width: 550px;
  }
}