@import "./styles/helpers.scss";
@import "./styles/breakpoints.scss";

:root {
  --primary: #72053a;
  --secondary: #fae9ee;
  --background: #f2f2f2;
  --text: #242423;
  --text-light: #656564;
  --secondary-button-text: #7c0d3c;
  --input-border-color: #e0e0e0;
  --primary-button-active: #8d2959;
  --button-disabled: #aaaaaa;
  --error-message: #d10000;
}

@font-face {font-family: 'IsidoraSans-SemiBold';src: url('assets/fonts/34C90D_16_0.eot');src: url('assets/fonts/34C90D_16_0.eot?#iefix') format('embedded-opentype'),url('assets/fonts/34C90D_16_0.woff2') format('woff2'),url('assets/fonts/34C90D_16_0.woff') format('woff'),url('assets/fonts/34C90D_16_0.ttf') format('truetype');}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 16px;
  font-family: 'IsidoraSans-SemiBold', Arial, Helvetica, sans-serif;
  background-color: var(--background);
  padding-top: 1em;
  color: var(--text);
  
  @include for-laptop-up {
    padding-top: 6em;
  }
}

h1, h2, h3, h4, p { padding: 0; margin: 0; }
h1, h2, h3 { font-weight: 600; }
h1 {font-size: 1.5em;}
h2 {font-size: 1.125em;}
h3 {font-size: 0.875em;};
h4 {font-size: 0.75em;}

section {
  margin-bottom: 25px;

  h2 + p {
    margin-top: 1em;
  }
}

p {
  line-height: 1.5;
}

input[type=text], input[type=email], input[type=tel], textarea {
  width: 100%;
  font-family: Sarabun, Arial, Helvetica, sans-serif;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  background-color: #ffffff;
  padding: 7px 12px 11px 12px;
  min-height: 42px;
}

input[type="radio"] {
  opacity: 0;
  position: absolute;
  
  + label {
    position: relative;
    display: inline-block;
    
    &:before {
      content: '';
      box-sizing: border-box;
      display: inline-block;
      position: absolute;
      top: 2px;
      left: -24px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.1), inset 0 1.5px 1px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #979797;
      background-color: #ffffff;
    }
    
    &:after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      display: inline-block;
      left: -21px;
      top: 5px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
    }
  }
  
  &:checked {
    + label::after {
      background: var(--primary);
    }
  }

  &:focus {
    + label:before {
      box-shadow: 0 0px 4px var(--primary);
    }
  }

  &:focus:not(:focus-visible) {
    + label:before {
      box-shadow: none;
    }
  }
  
  &:focus-visible {
    + label:before {
      box-shadow: 0 0px 4px var(--primary);
    }
  }
}

textarea {
  min-height: 85px;
}

.page-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text-light {
  color: var(--text-light);
}

.member-address {
  font-size: 1.125em;
  font-weight: 600;
}

.columns {
  @include for-laptop-up {
    display: flex;
  }
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;

  &:last-child {
    margin-top: 1em;
  }

  @include for-laptop-up {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
      margin-top: 0;
    }
  }
}

.error-message {
  color: var(--error-message);
  font-weight: 600;
}

.missing-field {
  font-style: italic;
  font-weight: normal;
}