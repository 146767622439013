@import "../../styles/breakpoints.scss";

button {
  cursor: pointer;
  font-size: 0.9375em;
  font-weight: 600;
  border-radius: 6px;
  border: 0;
  
  &.button-primary {
    color: #ffffff;
    background-color: var(--primary);
    padding: 14px 50px;
    
    @include for-laptop-up {
      padding: 14px 110px;
    }

    &:active {
      background-color: var(--primary-button-active);
    }
  }
  
  &.button-secondary {
    color: var(--secondary-button-text);
    background-color: var(--secondary);  
    padding: 13px 20px;
  }

  &[disabled] {
    background-color: var(--button-disabled);
    cursor: default;
  }
}